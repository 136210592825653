import React from "react";

import Header from "./header";
import Footer from "./Footer";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
    
      <main>{children}</main>

      <Footer />
    </div>
  );
}

export default Layout;
