import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import { get } from "lodash";
import Img from "gatsby-image";

import { urlToLink } from "../helper";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  
  const { file, blog } = useStaticQuery(graphql`
    query SiteTitleQuery {
      file(name: { eq: "logo" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blog {
        menus(where: { slug: "header" }) {
          nodes {
            id
            name
            slug
            menuItems {
              nodes {
                id
                url
                label
                isRestricted
              }
            }
          }
        }
      }
    }
  `);

  const headerMenu = get(blog, "menus.nodes[0].menuItems.nodes");

  return (
    <header className="bg-black-dark">
      <div className="flex flex-wrap items-center justify-between max-w-6xl p-4 mx-auto md:p-8 text-white">
        <Link to="/" className="no-underline block w-1/2 sm:w-1/4">
    
            <Img
              className="w-full"
              fluid={file.childImageSharp.fluid}
            />
     
        </Link>

        <button
          className="flex items-center block px-3 py-2  border border-white rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>


      

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto`}
        >
          {headerMenu &&
            headerMenu.map((menu) => (
              <Link
                className="block mt-4 underline hover:no-underline   md:inline-block md:mt-0 md:ml-6 font-family-louize md:text-2xl"
                key={menu.label}
                to={urlToLink(menu.url)}
              >
                {menu.label}
              </Link>
            ))}


              <button
                className="block mx-1 md:mt-0 md:ml-6 items-center block px-3 py-2  border border-white"
                onClick={() => setIsSearch(!isSearch)}
              >
                <svg className="w-3 h-3 fill-current text-white" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 339.921 339.921"  >
                  <path  d="M335.165,292.071l-81.385-84.077c-5.836-6.032-13.13-8.447-16.29-5.363
                    c-3.171,3.062-10.47,0.653-16.306-5.379l-1.164-1.207c36.425-47.907,32.89-116.499-10.851-160.24
                    c-47.739-47.739-125.142-47.739-172.875,0c-47.739,47.739-47.739,125.131,0,172.87c44.486,44.492,114.699,47.472,162.704,9.045
                    l0.511,0.533c5.825,6.032,7.995,13.402,4.814,16.469c-3.166,3.068-1.012,10.443,4.83,16.464l81.341,84.11
                    c5.836,6.016,15.452,6.195,21.49,0.354l22.828-22.088C340.827,307.735,340.99,298.125,335.165,292.071z M182.306,181.81
                    c-32.852,32.857-86.312,32.857-119.159,0.011c-32.852-32.852-32.847-86.318,0-119.164c32.847-32.852,86.307-32.847,119.148,0.005
                    C215.152,95.509,215.152,148.964,182.306,181.81z"/>
                </svg>
              </button>
            
              <div className={isSearch ? `block` : `hidden`}>
                <div className={`gcse-searchbox-only`}></div>
              </div>
        </nav>
        
      </div>
     
    </header>
    
  );
}

export default Header;
