export function pathToLink(path) {
  return path.replace(process.env.GATSBY_WP_DIRECTORY, "");
}

export function urlToLink(path) {
  return path.replace(process.env.GATSBY_WP_URL, "");
}

export function removeHtml(string) {
  return string ? string.replace(/(<([^>]+)>)/gi, "") : string;
}
