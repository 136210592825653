import React from "react";

export default React.memo(
  ({
    author,
    siteUrl,
    datePublished,
    dateModified,
    defaultTitle,
    description,
    defaultDescription,
    image,
    isBlogPost,
    organization,
    title,
    url,
    lang,
  }) => {
    const baseSchema = [
      {
        "@context": "http://schema.org",
        "@type": "WebSite",
        "@id": `${siteUrl}#website`,
        url: siteUrl,
        name: defaultTitle,
        description: defaultDescription,
        inLanguage: "pt-BR"
      },
      {
        "@type": "ImageObject",
        "@id": `${url}#primaryimage`,
        inLanguage: "pt-BR",
        url: image,
        width: 2048,
        height: 1152
      },
      {
        "@type": "WebPage",
        "@id": `${url}#webpage`,
        url: url,
        name: title,
        isPartOf: {
            "@id": `${siteUrl}#website`,
        },
        primaryImageOfPage: {
            "@id": `${url}#primaryimage`
        },
        inLanguage: "pt-BR",
        potentialAction: [{
            "@type": "ReadAction",
            target: [url]
        }]
      },
    ];



    const schema = isBlogPost
      ? [
          ...baseSchema,
          {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                item: {
                  "@id": url,
                  name: title,
                  image,
                },
              },
            ],
          },
          {
            "@type": ["Person"],
            "@id": `${url}#/schema/person/${author.id}`,
            name: author.name,
            image: {
                "@type": "ImageObject",
                "@id": `${url}#personlogo`,
                inLanguage: "pt-BR",
                url: author.avatar.url,
                caption: author.name
            },
            description: author.description
        },
          {
            "@context": "http://schema.org",
            "@type": "BlogPosting",
            url,
            name: title,
            alternateName: defaultTitle,
            headline: title,
            inLanguage: lang,
            image: {
              "@type": "ImageObject",
              url: image,
            },
            description,
            author: {
              "@type": "Person",
              "@id": `${url}#/schema/person/${author.id}`,
              name: author.name,
            },
            publisher: {
              "@type": "Organization",
              url: organization.url,
              logo: {
                "@type": "ImageObject",
                url: organization.logo,
                height: "56",
                width: "295",
              },
              name: organization.name,
            },
            mainEntityOfPage: {
              "@type": "WebSite",
              "@id": `${siteUrl}#website`,
            },
            datePublished,
            dateModified,
          },
        ]
      : baseSchema;

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schema),
        }}
      />
    );
  }
);
